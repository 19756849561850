<template>

<app-content :loading="is.loading">

	<app-content-head title="Settings">
		
		<template v-if="$authorised('con/volunteers/access', 'write')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />

		</template>

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/volunteers/access', 'read')">

			<app-input-checklist label="Visibility" v-model="model.volunteers.visibility" :validation="$v.model.volunteers.visibility" :options="$constants.volunteers.settings.visibilityName" :descriptions="$constants.volunteers.settings.visibilityDescription" :fullWidth="true" :singular="true" :columns="1" />
			<app-input-checklist v-if="model.volunteers.visibility !== $constants.volunteers.settings.visibility.any" label="Sign-ups" v-model="model.volunteers.signups" :validation="$v.model.volunteers.signups" :options="$constants.volunteers.settings.signupsName" :descriptions="$constants.volunteers.settings.signupsDescription" :fullWidth="true" :singular="true" :columns="1" />
			
		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/volunteers/access', 'read'),
			settings: true,
			model: {
				volunteers: {
					visibility: this.$constants.volunteers.settings.visibility.any,
					signups: 0
				}
			}
		}

	},

	validations: {
		model: {
			volunteers: {
				
			}
		}
	}

}

</script>

<style scoped>

</style>